import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../pages/Home'
import Services from '../pages/Services'
import ConseilStrategie from '../pages/ConseilStrategie'
import WebDigital from '../pages/WebDigital'
import GraphiqueDesign from '../pages/GraphiqueDesign'
import OpicDetails from '../pages/OpicDetails'
import Agence from '../pages/Agence'
import Contact from '../pages/Contact'

const Routers = () => {
  return (
   <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/services' element={<Services/>}/>
      <Route path='/conseil-strategie' element={<ConseilStrategie/>}/>
      <Route path='/digital-web' element={<WebDigital/>}/>
      <Route path='/graphique-design' element={<GraphiqueDesign/>}/>
      <Route path='/opic-details' element={<OpicDetails/>}/>
      <Route path='/a-propos' element={<Agence/>}/>
      <Route path='/contact' element={<Contact/>}/>
   </Routes>
  )
}

export default Routers