import React from "react";
import Header from "../components/Header";
import Routers from "../routes/Routers";
import Footer from "../components/Footer";
const LayOut = () => {
  return (
    <div>
      <Header />
      <div className="">
        <Routers />
      </div>
      <Footer />
    </div>
  );
};

export default LayOut;
