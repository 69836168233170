import React , {useState} from "react";
import logo from "../assets/EVO.png";
import Button from "./Button";
import { CiMenuFries } from "react-icons/ci";
import { HiXMark } from "react-icons/hi2";
import { useLocation } from 'react-router-dom';
const Header = () => {
  const [isOpen,setIsOpen] = useState(false)
  const location = useLocation();

  return (
    <header className=" z-40 justify-between items-center bg-[#222222] px-4 sm:px-6 md:px-12 lg:px-16 xl:px-20 py-3 flex lg:gap-[140px]  fixed w-full left-0 top-0">
      <div>
        <a href="/">
          <img src={logo} alt="" className=" w-[80px] h-[65px] md:w-[90px] md:h-[80px] lg:w-[100px] lg:h-[80px]" />
        </a>
      </div>
      {
       isOpen ? <HiXMark onClick={()=> setIsOpen(!isOpen)} className="  text-customBlue text-[24px] font-bold cursor-pointer lg:hidden block"/> :  <CiMenuFries onClick={()=> setIsOpen(!isOpen)} className="  text-customBlue text-[24px] font-bold cursor-pointer lg:hidden block"/>
      }
      {/* <div className={ ` hidden  w-full  lg:flex lg:justify-between text-center  items-center gap-10 left-0 transition-all duration-200`}> */}
      <div className={ ` hidden  w-full  lg:flex lg:flex-row lg:justify-end text-center  items-center gap-10 left-0 transition-all duration-200`}>
        <ul className={`w-fit flex items-center lg:gap-4 xl:gap-10 text-white font-medium capitalize ${isOpen ? 'flex-col gap-6' : ''}`}>
          <li className={`transition-all duration-300 hover:text-customBlue lg:text-[17px] relative after:w-0 after:transition-all after:duration-200 after:-bottom-3 after:content-[''] hover:after:w-full after:absolute after:bg-customBlue after:h-1 after:left-0 ${location.pathname === "/" ? 'after:w-full text-customBlue' : '  '}`}>
            <a href="/">Accueil</a>
          </li>
          <li className={`hover:text-customBlue lg:text-[17px] relative after:w-0 after:transition-all after:duration-200 after:-bottom-3 after:content-[''] hover:after:w-full after:absolute after:bg-customBlue after:h-1 after:left-0 ${location.pathname === "/a-propos" ? 'after:w-full text-customBlue' : '  '}`}>
            <a href="/a-propos">L’Agence</a>
          </li>
          <li className={`hover:text-customBlue lg:text-[17px] relative after:w-0 after:transition-all after:duration-200 after:-bottom-3 after:content-[''] hover:after:w-full after:absolute after:bg-customBlue after:h-1 after:left-0 ${location.pathname === "/services" ? 'after:w-full text-customBlue' : '  '}`}>
            <a href="/services">Nos Services</a>
          </li>
          <li className=" transition-all duration-300 hover:text-customBlue lg:text-[17px] relative after:w-0 after:transition-all after:duration-200 after:-bottom-3 after:content-[''] hover:after:w-full after:absolute after:bg-customBlue after:h-1 after:left-0">
            <a href="/">Portfolio</a>
          </li>
          {/* <li className=" transition-all duration-300 hover:text-customBlue lg:text-[17px] relative after:w-0 after:transition-all after:duration-200 after:-bottom-3 after:content-[''] hover:after:w-full after:absolute after:bg-customBlue after:h-1 after:left-0">
            <a href="/">Blog</a>
          </li> */}
        </ul>
        <Button lien="/contact" title="Contactez nous" bgColor="bg-customBlue" />
      </div>
      {/* In  Mobile */}
      <div className={ ` absolute top-[64px] bg-[#222222] left-0 h-screen overflow-hidden flex flex-col gap-8 pt-14 items-center  ${isOpen ? ' w-full block scale-100' : ' scale-0'}  w-full transition-all duration-200`}>
        <ul className={`w-fit items-center gap-8 flex items-centertext-white font-medium capitalize flex-col text-white`}>
          <li className={`${location.pathname === "/" ? 'after:w-full text-customBlue' : '  '} hover:text-customBlue lg:text-[17px] relative after:w-0 after:transition-all after:duration-200 after:-bottom-3 after:content-[''] hover:after:w-full after:absolute after:bg-customBlue after:h-1 after:left-0`} >
            <a href="/">Accueil</a>
          </li>
          <li className={`${location.pathname === "/a-propos" ? 'after:w-full text-customBlue' : '  '} hover:text-customBlue lg:text-[17px] relative after:w-0 after:transition-all after:duration-200 after:-bottom-3 after:content-[''] hover:after:w-full after:absolute after:bg-customBlue after:h-1 after:left-0`} >
            <a href="/a-propos">L’Agence</a>
          </li>
          <li className=" transition-all duration-300 hover:text-customBlue lg:text-[17px] relative after:w-0 after:transition-all after:duration-200 after:-bottom-3 after:content-[''] hover:after:w-full after:absolute after:bg-customBlue after:h-1 after:left-0">
            <a href="/services">Nos Services</a>
          </li>
          <li className=" transition-all duration-300 hover:text-customBlue lg:text-[17px] relative after:w-0 after:transition-all after:duration-200 after:-bottom-3 after:content-[''] hover:after:w-full after:absolute after:bg-customBlue after:h-1 after:left-0">
            <a href="/">Portfolio</a>
          </li>
          {/* <li className=" transition-all duration-300 hover:text-customBlue lg:text-[17px] relative after:w-0 after:transition-all after:duration-200 after:-bottom-3 after:content-[''] hover:after:w-full after:absolute after:bg-customBlue after:h-1 after:left-0">
            <a href="/">Blog</a>
          </li> */}
        </ul>
        <Button lien="" title="Contactez nous" bgColor="bg-customBlue" />
      </div>
    </header>
  );
};

export default Header;
