import web from "../assets/services/web.jpg";
import marketing from "../assets/services/marketing.jpg";
import video from "../assets/services/video.jpg";
import opic from "../assets/Partners/opic.png";
import mpm from "../assets/Partners/mpm.png";
import audis from "../assets/Partners/audis.png";
import chronovision from "../assets/Partners/chronovision.png";
import essaber from "../assets/Partners/essaber.png";
import electrosky from "../assets/Partners/electrosky.png";
import opicProject from "../assets/projects/opic.png";
import mpmProject from "../assets/projects/MPM.png";
// images services
import reseaux_sociaux from "../assets/services/reseaux sociaux.jpg";
import stratégie from "../assets/services/Conseil & Stratégie.jpg";
import webDev from "../assets/services/web dev.webp";
import graphic from "../assets/services/graphic design.jpg";

const services = [
  {
    id: 1,
    name: "Conseil & stratégie",
    image: marketing,
    rol: "Suivi, conseil et élaboration de stratégie",
    img: stratégie,
    description:
      "Suivi, conseil et élaboration de stratégie de communication et de marketing",
    lien: "/conseil-strategie",
  },
  {
    id: 2,
    name: "Développement web",
    rol: "Création de votre site web",
    image: web,
    img: webDev,
    description:
      "Création de sites internet, élaboration et exécution de stratégies digitales",
    lien: "/digital-web",
  },
  // {
  //   id: 3,
  //   name: "Graphique Design",
  //   rol: "Création de votre identité visuelle",
  //   image: video,
  //   img: graphic,
  //   description:
  //     "Création d'identités visuelles et tout support de communication",
  //   lien: "/graphique-design",
  // },
  // {
  //   id: 4,
  //   name: "Reseaux Sociaux",
  //   rol: "Gestion de vos réseaux sociaux",
  //   img: reseaux_sociaux,
  //   image: video,
  //   description:
  //     "Création d'identités visuelles et tout support de communication",
  //   lien: "/graphique-design",
  // },
  {
    id: 5,
    name: "Marketing Digital",
    rol: "Gestion de vos réseaux sociaux et création de votre identité visuelle",
    img: reseaux_sociaux,
    image: video,
    description:
      "Création d'identités visuelles et tout support de communication",
    lien: "/graphique-design",
  },
];

const partners = [
  {
    name: "Maison Paramed",
    image: mpm,
    message: "",
  },
  {
    name: "Audis",
    image: audis,
    message: "",
  },
  {
    name: "Opic",
    image: opic,
    message: "",
  },
  {
    name: "Chronovision",
    image: chronovision,
    message: "",
  },
  {
    name: "Essaber",
    image: essaber,
    message: "",
  },
  {
    name: "Electrosky",
    image: electrosky,
    message: "",
  },
];
const projects = [
  {
    id: 1,
    name: "Opic - Site web Static",
    image: opicProject,
    category: "Developpement Web",
    lien: "/opic-details",
  },
  {
    name: "Maison Paramed - Site web Static",
    image: mpmProject,
    category: "Developpement Web",
    lien: "/opic-details",
  },
  {
    id: 2,
    name: "Opic - Site web Static",
    image: opicProject,
    category: "Developpement Web",
    lien: "/opic-details",
  },
  {
    id: 3,
    name: "Maison Paramed - Site web Static",
    image: mpmProject,
    category: "Developpement Web",
    lien: "/opic-details",
  },
  {
    id: 4,
    name: "Opic - Site web Static",
    image: opicProject,
    category: "Developpement Web",
    lien: "/opic-details",
  },
  {
    id: 5,
    name: "Maison Paramed - Site web Static",
    image: mpmProject,
    category: "Developpement Web",
    lien: "/opic-details",
  },
];
const testimonials = [
  {
    id: 1,
    company: "Opic",
    name: "Khadija El Mardi",
    post: "Directrice des Partenariats",
    desc: "Je ne peux que recommander ce service. L'équipe a su répondre à mes besoins de manière rapide et efficace. Leur expertise m'a permis de gagner du temps et d'atteindre mes objectifs bien plus facilement que je ne l'aurais imaginé. Un service professionnel et humain à la fois !",
    image: opic,
  },
  {
    id: 4,
    company: "Maison Paramed",
    name: "Mohamed El Rahmani",
    post: "Manager",
    desc: "Le service a été irréprochable du début à la fin. Ils ont pris le temps de comprendre mes besoins, ont proposé des solutions sur mesure et m'ont accompagné tout au long du projet. Je suis ravi du résultat et je n'hésiterai pas à faire de nouveau appel à eux.",
    image: mpm,
  },
  {
    id: 3,
    company: "Audis",
    name: "Aya Safouane",
    post: "Directrice",
    desc: "Je ne peux que recomJe suis très satisfait du service. L'équipe a été à l'écoute, disponible et toujours de bon conseil. Ils ont su anticiper mes attentes et m'ont guidé tout au long du processus. Un service client exemplaire, je recommande sans hésitationmander ce service. L'équipe a su répondre à mes besoins de manière rapide et efficace. Leur expertise m'a permis de gagner du temps et d'atteindre mes objectifs bien plus facilement que je ne l'aurais imaginé. Un service professionnel et humain à la fois !",
    image: audis,
  },
  {
    id: 4,
    company: "Essaber ",
    name: "Aya Safouane",
    post: "Directrice",
    desc: "Une prestation de qualité à tous les niveaux ! Le service a été personnalisé, rapide et extrêmement professionnel. J'ai particulièrement apprécié la réactivité de l'équipe face à mes demandes. C'est rare de trouver un service aussi complet.",
    image: essaber,
  },
  {
    id: 5,
    company: "Chronovision",
    name: "Aya Safouane",
    post: "Directrice",
    desc: "Le service fourni a surpassé mes attentes. Tout a été parfaitement orchestré, du premier contact à la finalisation du projet. Ils m'ont tenu informé à chaque étape et ont veillé à ce que tout soit conforme à mes attentes. Je suis vraiment impressionné par leur professionnalisme.",
    image: chronovision,
  },
];
export { services, partners, projects, testimonials };
