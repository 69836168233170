import React, { useEffect } from "react";
import { TopHead } from "../components/TopHead";
import PartnersComponent from "../components/PartnersComponent";
import video from "../assets/evomarket.mp4";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import CountUp from "react-countup";
import { FaPersonWalkingArrowRight } from "react-icons/fa6";
import Testimonials from "../components/Testimonials";

const Agence = () => {
  return (
    <div>
      <TopHead
        bgImage={null}
        SubTitle="Une agence de communication globale"
        Maintitle="Evomarket Studio de Création"
      />
      <div className=" mt-8 px-4 sm:px-10 md:px-16 xl:px-36">
        <h1
          data-aos="fade-right"
          className={`  w-fit before:content-[''] before:absolute before:z-[-1] z-10 before:top-5  relative before:w-[60%] before:h-2 before:bg-customBlue  text-customGold uppercase text-[20px] md:text-[22px] lg:text-[26px] font-bold`}
        >
          Qui sommes-nous ?
        </h1>
        <div className=" flex gap-6 md:flex-row justify-between flex-col md:gap-10 lg:gap-[100px] items-start mt-8">
          <div className=" w-full md:w-[70%] ">
            <Video
              autoPlay
              loop
              controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
              poster=""
              onCanPlayThrough={() => {
                // Do stuff
              }}
            >
              <source src={video} type="video/webm" />
              <track
                label="English"
                kind="subtitles"
                srcLang="en"
                src="http://source.vtt"
                default
              />
            </Video>
            <p className=" text-[15px] md:text-[16px] lg:text-[17px]  text-white py-6">
              Evomarket Studio de Création a vu le jour en 1996, comme studio
              photo doté d’une réputation irréprochable. Sa qualité de travail
              lui a permis de participer à de nombreux projets de renommée
              internationale. Quelques années plus tard, Ettayssir élargit ses
              services et devient une boite de communication opérant dans le
              secteur de l’audiovisuel, l’évènementiel, l’imprimerie et
              l’aménagement intérieur et extérieur. Le portfolio de l’agence
              Ettayssir Studio de Création s’est élargi avec le temps et son
              équipe aussi. 2021, Ettayssir devient une boite de communication
              globale (agence 360°), et se lance dans le monde digital en
              proposant à ses clients de meilleures solutions. La team se
              compose aujourd’hui de designers, de commerciaux, de digital
              manager et chefs de projet qui font tout le succès de l’agence.
              Ettayssir Studio de Création repose sur trois éléments majeurs, à
              savoir la réactivité, l’engagement et la qualité. Des valeurs qui
              déterminent aujourd’hui son image ainsi que son travail.
            </p>
          </div>
          <div className=" py-3 text-white">
            <ul className=" flex flex-col gap-2 w-fit">
              <li className=" after:content-[''] after:w-[50%] after:h-2 after:-z-10 text-customGold font-medium text-[26px] after:absolute relative after:top-5 after:left-0 after:bg-customBlue ">
                Découvrir
              </li>
              <li>
                <a href="/services" className=" flex items-center gap-2 hover:text-customGold">Nos Services <FaPersonWalkingArrowRight/></a>
              </li>
              <li>
                <a href="/" className=" flex items-center gap-2 hover:text-customGold">Nos Références <FaPersonWalkingArrowRight/></a>
              </li>
              <li>
                <a href="/" className=" flex items-center gap-2 hover:text-customGold">Nos Articles <FaPersonWalkingArrowRight/></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className=" my-32 bg-customGold/5 py-14" >
      <div className="  px-4 sm:px-10 md:px-16 xl:px-36">
      <h1
          data-aos="fade-right"
          className={`  w-fit before:content-[''] before:absolute before:z-[-1] z-10 before:top-5  relative before:w-[60%] before:h-2 before:bg-customBlue  text-customGold uppercase text-[20px] md:text-[22px] lg:text-[26px] font-bold`}
        >
          Evoamrket en chiffres
        </h1>

        <ul className=" w-full justify-around flex flex-col md:flex-row items-center py-10">
          <li className=" flex flex-col gap-2 items-center text-white">
            <span className=" text-[40px] md:text-[50px] font-bold text-customGold">
                +<CountUp start={0} end={10} duration={5}/>
            </span>
            <p className=" text-customBlue text-[20px] md:text-[23px]">Années d'experience</p>
          </li>
          <li className=" flex flex-col gap-2 items-center text-white">
            <span className=" text-[40px] md:text-[50px] font-bold text-customGold"> +<CountUp start={0} end={20} duration={5}/></span>
            <p className=" text-customBlue text-[20px] md:text-[23px]">Clients satisfaits</p>
          </li>
          <li className=" flex flex-col gap-2 items-center text-white">
            <span className=" text-[40px] md:text-[50px] font-bold text-customGold"> +<CountUp start={0} end={30} duration={5}/></span>
            <p className=" text-customBlue text-[20px] md:text-[23px]">Projets réalisés</p>
          </li>
        </ul>
      </div>
      </div>
      <div>
        <PartnersComponent />
      </div>
      <div className="px-4 sm:px-10 md:px-16 xl:px-36 my-[100px]">
        <Testimonials />
      </div>
    </div>
  );
};

export default Agence;
