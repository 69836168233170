import React, { useEffect } from "react";
import Button from "./Button";
import Aos from "aos";

const Title = ({ title, desc, textButton }) => {
  useEffect(()=> {
    Aos.init({
      duration:1100
    }) 
  },[])
  return (
    <div className=" flex gap-4 flex-col">
      <h1
      data-aos="fade-right"
        className={`  w-fit before:content-[''] before:absolute before:z-[-1] z-10 before:top-5  relative before:w-[60%] before:h-2 before:bg-customBlue  text-customGold uppercase text-[20px] md:text-[22px] lg:text-[26px] font-bold`}
      >
        {title}
      </h1>
      <p  className=" text-justify text-white lg:w-[450px]">{desc}</p>
     <div className=" mt-4">
     <Button title={textButton} lien="/services"/>
     </div>
    </div>
  );
};

export default Title;
